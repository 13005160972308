import React from 'react'
import styled from 'styled-components'
import CenterContainer from '../components/CenterContainer'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { graphql } from 'gatsby'
import arrowImage from '../assets/arrow.svg'

const Wrapper = styled.div`
  margin-top: 10vh;
  height: 300px;
  font-family: 'Inconsolata', monospace;
`

const StyledPre = styled.div`  
  border-radius: 10px;
  width: 300px;
  word-wrap: break-word;
  padding: 1em;
  background: #f5f2f0;
  text-align: left;
  @media only screen and (max-width: 750px) {
    width: 200px;
  }
`

const StyledArrow = styled.img`
  transform: rotate(-20deg);
  margin-bottom: 20px;
  @media only screen and (max-width: 1000px) {
    transform: rotate(-40deg);
  }
`

const ItIsSupposedToBeLikeThatWrapper = styled.div`
  position: relative;
  right: 50%;
  z-index: 1;
  @media only screen and (max-width: 1000px) {
    right: 0;
    top: 10px;
  }
`

export default ({ data, location }) => {
  const { email, github, linkedin } = data.site.siteMetadata.social

  return (
    <Layout location={location}>
      <SEO title="Contact" />
        <CenterContainer>
          <Wrapper>
            <StyledPre className='language-js'>
              {btoa(JSON.stringify({
                email,
                github: `https://github.com/${github}`,
                linkedin: `https://linkedin.com/in/${linkedin}`
              }))}
            </StyledPre>
            <ItIsSupposedToBeLikeThatWrapper>
                <StyledArrow src={arrowImage} />
                <div>
                  It's supposed to be like that.
                </div>
              </ItIsSupposedToBeLikeThatWrapper>
          </Wrapper>
        </CenterContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        social {
          email
          github
          linkedin
        }
      }
    }
  }
`
